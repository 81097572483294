import React, { useState, useEffect } from "react";

const CertificateTwentyTwo = () => {
  const editId = window.sessionStorage.getItem("editId");
  const cerId = window.sessionStorage.getItem("cerId");

  var curr = new Date();
  var date = curr.toISOString().substr(0, 10);

  var currto = new Date();
  currto.setDate(currto.getDate() + 365);
  var todate = currto.toISOString().substr(0, 10);

  const [buttonText, setButtonText] = useState("Save");
  const [cCertificate, setCCertificate] = useState("MRL/MS-1904");
  const [dawnDataSheet, setDawnDataSheet] = useState("");
  const [equipment, setEquipment] = useState("Microscope");
  const [section, setSection] = useState("Q.C Microbiology Lab.");
  const [model, setModel] = useState("XSZ-107BN");
  const [labTemp, setLabTemp] = useState("25°C");
  const [instrId, setInstrId] = useState("QC-EQ-MIC-008");
  const [labHumidity, setlabHumidity] = useState("50% RH");
  const [manufacturer, setManufacturer] = useState("CHINA");
  const [calibDate, setCalibDate] = useState(date);
  const [client, setClient] = useState("");
  const [nextCalib, setNextCalib] = useState(todate);
  const [issueDate, setIssueDate] = useState(date);
  const [operatorName, setOperatorName] = useState("");
  const [traceMeasure, setTraceMeasure] = useState(
    "The microscope is calibrated against for magnifying power lenses that are traceable to the certificate # DW/0829-13 to NIST through Labomed USA. [Combined Uncertainty (U) ± 1.02, coverage factor K=2 & Level of Confidence: 95%]"
  );
  const [methodUsed, setMethodUsed] = useState("Direct Comparison Method");
  const [customerList, setCustomerList] = useState([]);

  const getCertificateTwentyTwo = async () => {
    let cusRes = await fetch(
      window.api + "getCertificateData/" + editId + "/" + cerId
    );
    cusRes = await cusRes.json();
    setCustomerList(cusRes.customers);
    setCCertificate(cusRes.certificateData.c_certificate);
    setDawnDataSheet(cusRes.certificateData.dawn_data_sheet);
    setEquipment(cusRes.certificateData.equipment);
    setSection(cusRes.certificateData.section);
    setModel(cusRes.certificateData.model);
    setLabTemp(cusRes.certificateData.lab_temp);
    setInstrId(cusRes.certificateData.instr_id);
    setlabHumidity(cusRes.certificateData.lab_humidity);
    setManufacturer(cusRes.certificateData.manufacturer);
    setCalibDate(cusRes.certificateData.calib_date);
    setClient(cusRes.certificateData.user_id);
    setNextCalib(cusRes.certificateData.next_calib);
    setIssueDate(cusRes.certificateData.issue_date);
    setOperatorName(cusRes.certificateData.operator_name);
    setMethodUsed(cusRes.certificateData.method_used);
    setTraceMeasure(cusRes.certificateData.trace_measure);
  };
  const getCustomerList = async () => {
    let cusRes = await fetch(window.api + "customerList");
    cusRes = await cusRes.json();
    setCustomerList(cusRes);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (editId && cerId) {
      getCertificateTwentyTwo();
    } else {
      getCustomerList();
    }
  }, []);

  async function Submit() {
    setButtonText("Saving");
    const formData = new FormData();
    formData.append("edit_id", editId);
    formData.append("cer_id", 22);
    formData.append("user_id", client);
    formData.append("c_certificate", cCertificate);
    formData.append("dawn_data_sheet", dawnDataSheet);
    formData.append("equipment", equipment);
    formData.append("section", section);
    formData.append("model", model);
    formData.append("lab_temp", labTemp);
    formData.append("instr_id", instrId);
    formData.append("lab_humidity", labHumidity);
    formData.append("manufacturer", manufacturer);
    formData.append("calib_date", calibDate);
    formData.append("next_calib", nextCalib);
    formData.append("issue_date", issueDate);
    formData.append("operator_name", operatorName);
    formData.append("trace_measure", traceMeasure);
    formData.append("method_used", methodUsed);

    let result = await fetch(window.api + "addTwentyTwoCertificate", {
      method: "POST",
      body: formData,
    });

    result = await result.json();
    if (result.success) {
      setButtonText("Saved Successfully");
      sessionStorage.removeItem("editId");
      sessionStorage.removeItem("cerId");
    }
  }

  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">Add Certificate Details</h3>
        </div>
        <div className="card-body">
          <label>Main Entries:</label>

          <div className="row">
            <div className="col-sm-3">
              <label>Certificate #:</label>
              <input
                type="text"
                value={cCertificate}
                onChange={(e) => setCCertificate(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Dawn Data Sheet No.:</label>
              <input
                type="text"
                value={dawnDataSheet}
                onChange={(e) => setDawnDataSheet(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Equipment:</label>
              <input
                type="text"
                value={equipment}
                onChange={(e) => setEquipment(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Section:</label>
              <input
                type="text"
                value={section}
                onChange={(e) => setSection(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Model/Type: </label>
              <input
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Laboratory Temperature: </label>
              <input
                type="text"
                value={labTemp}
                onChange={(e) => setLabTemp(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Instrument ID: </label>
              <input
                type="text"
                value={instrId}
                onChange={(e) => setInstrId(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Laboratory Humidity: </label>
              <input
                type="text"
                value={labHumidity}
                onChange={(e) => setlabHumidity(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Manufacturer: </label>
              <input
                type="text"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Issue Date:</label>
              <input
                type="date"
                value={issueDate}
                onChange={(e) => setIssueDate(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Calibrated Date:</label>
              <input
                type="date"
                value={calibDate}
                onChange={(e) => setCalibDate(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Client:</label>
              <select
                value={client}
                onChange={(e) => setClient(e.target.value)}
                className="form-control form-control-sm"
              >
                <option value="">--Select Client--</option>
                {Object.keys(customerList).length > 0 ? (
                  customerList.map((c) => (
                    <option value={c.id}>
                      {c.name}({c.address})
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
            <div className="col-sm-3">
              <label>Next Calibration Date:</label>
              <input
                type="date"
                value={nextCalib}
                onChange={(e) => setNextCalib(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Operator Name:</label>
              <input
                type="text"
                value={operatorName}
                onChange={(e) => setOperatorName(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12">
              <lable>
                <b>Traceability of Measurements:</b>
              </lable>
              <textarea
                className="form-control"
                value={traceMeasure}
                onChange={(e) => setTraceMeasure(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <lable>
                <b>Method Used:</b>
              </lable>
              <textarea
                className="form-control"
                value={methodUsed}
                onChange={(e) => setMethodUsed(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            style={{ float: "right" }}
            onClick={Submit}
            disabled={
              !client ||
              !cCertificate ||
              !dawnDataSheet ||
              !equipment ||
              !section ||
              !model ||
              !labTemp ||
              !instrId ||
              !labHumidity ||
              !manufacturer ||
              !calibDate ||
              !nextCalib
            }
            className="btn btn-success"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
};

export default CertificateTwentyTwo;
