import React from 'react'

const Certificate = () => {
    return (
        <>
            
        </>
    )
}

export default Certificate
