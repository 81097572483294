import React, { useState, useEffect } from "react";
import Header from "./layout/Header";
import SideNav from "./layout/SideNav";
import Footer from "./layout/Footer";
import "./layout/css/style.css";

const InstructionManual = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [buttonText, setButtonText] = useState("Save");
  const [success, setSuccess] = useState("");
  const [manualList, setManualList] = useState([]);

  const getManual = async () => {
    let manualRes = await fetch(window.api + "manualList");
    manualRes = await manualRes.json();
    setManualList(manualRes);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getManual();
  }, []);

  const handleFile = (file) => {
    setFile(file[0]);
  };

  async function addManual() {
    setButtonText("Saving...");
    const formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("file", file);

    let result = await fetch(window.api + "addManual", {
      method: "POST",
      body: formData,
    });
    result = await result.json();
    if (result.success) {
      setButtonText("Save");
      setSuccess(true);
      setTitle("");
      setFile("");
      setId("");
      getManual();
      window.scrollTo(0, 0);
    }
  }

  function updateManual(id, name) {
    setId(id);
    setTitle(name);
    setButtonText("Update");
  }

  async function delManual(id) {
    let delRes = await fetch(window.api + "delManual/" + id);
    delRes = await delRes.json();
    if (delRes) {
      getManual();
    }
  }
  return (
    <>
      <div className="wrapper">
        <Header />
        <SideNav />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Instruction Manual</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Instrution Manual
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Add Instruction Manual</h3>
                    </div>
                    <div className="card-body">
                      {success ? (
                        <div className="alert alert-success alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                          Done Successfully..
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="form-control"
                              placeholder="Manual Title"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>PDF File</label>
                            <input
                              type="file"
                              onChange={(e) => handleFile(e.target.files)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          onClick={addManual}
                          className="btn btn-primary"
                          disabled={!title || !file}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">All Instruction Manual</h3>
                    </div>

                    <div className="card-body p-0">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>File</th>
                            <th>Name</th>
                            {/* <th>Edit</th> */}
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(manualList).length > 0 ? (
                            manualList.map((t) => (
                              <tr>
                                <td>
                                  {t.file != null ? (
                                    <a
                                      href={
                                        window.main +
                                        "downloadManual/" +
                                        t.file
                                      }
                                      download={t.file}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  ) : (
                                    <a></a>
                                  )}
                                </td>
                                <td>{t.title}</td>
                                {/* <td>
                                  <i
                                    onClick={() =>
                                      updateManual(t.id, t.name)
                                    }
                                    class="fas fa-user-edit"
                                    title="Edit user"
                                  ></i>
                                </td> */}
                                <td>
                                  <i
                                    onClick={() => delManual(t.id)}
                                    class="fas fa-trash-alt"
                                    title="Delete Team"
                                  ></i>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
        <Footer />
      </div>
    </>
  );
};

export default InstructionManual;
