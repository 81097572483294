import React, { useState, useEffect } from "react";

const CertificateThirtyOne = () => {
  const editId = window.sessionStorage.getItem("editId");
  const cerId = window.sessionStorage.getItem("cerId");

  var curr = new Date();
  var date = curr.toISOString().substr(0, 10);

  var currto = new Date();
  currto.setDate(currto.getDate() + 365);
  var todate = currto.toISOString().substr(0, 10);

  const [buttonText, setButtonText] = useState("Save");
  const [cCertificate, setCCertificate] = useState("MRL/PM-7715");
  const [dawnDataSheet, setDawnDataSheet] = useState("");
  const [equipment, setEquipment] = useState("Polarimeter");
  const [section, setSection] = useState("QC. Lab.");
  const [model, setModel] = useState("P810");
  const [labTemp, setLabTemp] = useState("25°C");
  const [instrId, setInstrId] = useState("N/A");
  const [labHumidity, setlabHumidity] = useState("50% RH");
  const [manufacturer, setManufacturer] = useState("N/A");
  const [calibDate, setCalibDate] = useState(date);
  const [client, setClient] = useState("");
  const [nextCalib, setNextCalib] = useState(todate);
  const [issueDate, setIssueDate] = useState(date);
  const [operatorName, setOperatorName] = useState("");
  const [phRange, setPhRange] = useState("");
  const [phResolution, setPhResolution] = useState("");
  const [methodUsed1, setMethodUsed1] = useState("");
  const [traceMeasure, setTraceMeasure] = useState(
    "The Polarimeter has been calibrated against reference standard observation tube 100-200mm; Distilled water was used for zero adjustment. The reference standards were showed the same results and met the manufacturer specification.   These reference standards are USP reference Standards that are traceable to Certificate # ACRC/0231, which in turn traceable to NIST USA."
  );
  const [pmParameters, setPmParameters] = useState([
    { appliedValue: "", observedValue: "", deviation: "", uncertainty: "" },
  ]);
  const [customerList, setCustomerList] = useState([]);

  const getCertificateThirtyOne = async () => {
    let cusRes = await fetch(
      window.api + "getCertificateData/" + editId + "/" + cerId
    );
    cusRes = await cusRes.json();
    setCustomerList(cusRes.customers);
    setCCertificate(cusRes.certificateData.c_certificate);
    setDawnDataSheet(cusRes.certificateData.dawn_data_sheet);
    setEquipment(cusRes.certificateData.equipment);
    setSection(cusRes.certificateData.section);
    setModel(cusRes.certificateData.model);
    setLabTemp(cusRes.certificateData.lab_temp);
    setInstrId(cusRes.certificateData.instr_id);
    setlabHumidity(cusRes.certificateData.lab_humidity);
    setManufacturer(cusRes.certificateData.manufacturer);
    setCalibDate(cusRes.certificateData.calib_date);
    setClient(cusRes.certificateData.user_id);
    setNextCalib(cusRes.certificateData.next_calib);
    setIssueDate(cusRes.certificateData.issue_date);
    setOperatorName(cusRes.certificateData.operator_name);
    setPhRange(cusRes.certificateData.ph_range);
    setPhResolution(cusRes.certificateData.ph_resolution);
    
    setMethodUsed1(cusRes.certificateData.method_used1);
    setTraceMeasure(cusRes.certificateData.trace_measure);
    if (cusRes.parameter1 !== null) {
      setPmParameters(cusRes.parameter1);
    }
  };
  const getCustomerList = async () => {
    let cusRes = await fetch(window.api + "customerList");
    cusRes = await cusRes.json();
    setCustomerList(cusRes);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (editId && cerId) {
      getCertificateThirtyOne();
    } else {
      getCustomerList();
    }
  }, []);

  // handle input change
  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;
    const list = [...pmParameters];
    list[index][name] = value;
    setPmParameters(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick2 = (index) => {
    const list = [...pmParameters];
    list.splice(index, 1);
    setPmParameters(list);
  };

  // handle click event of the Add button
  const handleAddClick2 = () => {
    setPmParameters([
      ...pmParameters,
      { appliedValue: "", observedValue: "", deviation: "", uncertainty: "" },
    ]);
  };

  async function Submit() {
    setButtonText("Saving");

    const formData = new FormData();
    formData.append("edit_id", editId);
    formData.append("cer_id", 31);
    formData.append("user_id", client);
    formData.append("c_certificate", cCertificate);
    formData.append("dawn_data_sheet", dawnDataSheet);
    formData.append("equipment", equipment);
    formData.append("section", section);
    formData.append("model", model);
    formData.append("lab_temp", labTemp);
    formData.append("instr_id", instrId);
    formData.append("lab_humidity", labHumidity);
    formData.append("manufacturer", manufacturer);
    formData.append("calib_date", calibDate);
    formData.append("next_calib", nextCalib);
    formData.append("issue_date", issueDate);
    formData.append("operator_name", operatorName);
    formData.append("ph_range", phRange);
    formData.append("ph_resolution", phResolution);
    formData.append("pm_parameters", JSON.stringify(pmParameters));
    formData.append("method_used1", methodUsed1);
    formData.append("trace_measure", traceMeasure);

    let result = await fetch(window.api + "addThirtyOneCertificate", {
      method: "POST",
      body: formData,
    });
    result = await result.json();

    if (result.success) {
      setButtonText("Saved Successfully");
      sessionStorage.removeItem("editId");
      sessionStorage.removeItem("cerId");
    }
  }
  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">Add Certificate Details</h3>
        </div>
        <div className="card-body">
          <label>Main Entries:</label>

          <div className="row">
            <div className="col-sm-3">
              <label>Certificate #:</label>
              <input
                type="text"
                value={cCertificate}
                onChange={(e) => setCCertificate(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Dawn Data Sheet No.:</label>
              <input
                type="text"
                value={dawnDataSheet}
                onChange={(e) => setDawnDataSheet(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Equipment:</label>
              <input
                type="text"
                value={equipment}
                onChange={(e) => setEquipment(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Section:</label>
              <input
                type="text"
                value={section}
                onChange={(e) => setSection(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Model/Type: </label>
              <input
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Laboratory Temperature: </label>
              <input
                type="text"
                value={labTemp}
                onChange={(e) => setLabTemp(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Instrument ID: </label>
              <input
                type="text"
                value={instrId}
                onChange={(e) => setInstrId(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Laboratory Humidity: </label>
              <input
                type="text"
                value={labHumidity}
                onChange={(e) => setlabHumidity(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Manufacturer: </label>
              <input
                type="text"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Issue Date:</label>
              <input
                type="date"
                value={issueDate}
                onChange={(e) => setIssueDate(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Calibrated Date:</label>
              <input
                type="date"
                value={calibDate}
                onChange={(e) => setCalibDate(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Client:</label>
              <select
                value={client}
                onChange={(e) => setClient(e.target.value)}
                className="form-control form-control-sm"
              >
                <option value="">--Select Client--</option>
                {Object.keys(customerList).length > 0 ? (
                  customerList.map((c) => (
                    <option value={c.id}>
                      {c.name}({c.address})
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
            <div className="col-sm-3">
              <label>Next Calibration Date:</label>
              <input
                type="date"
                value={nextCalib}
                onChange={(e) => setNextCalib(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-sm-3">
              <label>Operator Name:</label>
              <input
                type="text"
                value={operatorName}
                onChange={(e) => setOperatorName(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <hr />
          {/* <div className='row'>
                        <div className='col-sm-1'>
                            <label>Range:</label>
                        </div>
                        <div className='col-sm-3'>
                            <input type='text' value={phRange} onChange={(e) => setPhRange(e.target.value)} className='form-control form-control-sm' />
                        </div>
                        <div className='col-sm-4'></div>
                        <div className='col-sm-1'>
                            <label>Resolution:</label>
                        </div>
                        <div className='col-sm-3'>
                            <input type='text' value={phResolution} onChange={(e) => setPhResolution(e.target.value)} className='form-control form-control-sm' />
                        </div>
                    </div>                     */}

          <div className="row">
            <div className="col-sm-3">
              <center>
                <label>Concentration of sucrose</label>
              </center>
            </div>
            <div className="col-sm-3">
              <center>
                <label>Optical Rotations</label>
              </center>
            </div>
            <div className="col-sm-3">
              <center>
                <label>Observed Value</label>
              </center>
            </div>
            <div className="col-sm-3">
              <label>Deviation / Error</label>
            </div>
          </div>
          {pmParameters.map((t, i) => {
            return (
              <>
                <div className="row">
                  <div className="col-sm-3 p-0">
                    <div style={{ marginLeft: "10px" }}>
                      <input
                        type="text"
                        name="appliedValue"
                        className="form-control form-control-sm"
                        value={t.appliedValue}
                        onChange={(e) => handleInputChange2(e, i)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 p-0">
                    <div style={{ marginLeft: "2px" }}>
                      <input
                        type="text"
                        name="observedValue"
                        className="form-control form-control-sm"
                        value={t.observedValue}
                        onChange={(e) => handleInputChange2(e, i)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 p-0">
                    <div style={{ marginLeft: "2px" }}>
                      <input
                        type="text"
                        name="deviation"
                        className="form-control form-control-sm"
                        value={t.deviation}
                        onChange={(e) => handleInputChange2(e, i)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 p-0">
                    <div style={{ marginLeft: "2px" }}>
                      <input
                        type="text"
                        name="uncertainty"
                        className="form-control form-control-sm"
                        value={t.uncertainty}
                        onChange={(e) => handleInputChange2(e, i)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-1" style={{ paddingTop: "8px" }}>
                    {pmParameters.length !== 1 && (
                      <i
                        className="fas fa-times-circle"
                        onClick={() => handleRemoveClick2(i)}
                      ></i>
                    )}
                    {pmParameters.length - 1 === i && (
                      <i
                        className="fas fa-plus-circle"
                        onClick={handleAddClick2}
                      ></i>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          <hr />
          <div className="row">
            <div className="col-sm-12">
              <lable>
                <b>Method Used:</b>
              </lable>
              <textarea
                className="form-control"
                value={methodUsed1}
                onChange={(e) => setMethodUsed1(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <lable>
                <b>Traceability of Measurements:</b>
              </lable>
              <textarea
                className="form-control"
                value={traceMeasure}
                onChange={(e) => setTraceMeasure(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            style={{ float: "right" }}
            onClick={Submit}
            disabled={
              !client ||
              !cCertificate ||
              !dawnDataSheet ||
              !equipment ||
              !section ||
              !model ||
              !labTemp ||
              !instrId ||
              !labHumidity ||
              !manufacturer ||
              !calibDate ||
              !nextCalib
            }
            className="btn btn-success"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
};

export default CertificateThirtyOne;
