import React, { useState } from "react";
import Header from "./layout/Header";
import SideNav from "./layout/SideNav";
import Footer from "./layout/Footer";
import { useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist/webpack";

const ManualCertificate = () => {
  const [editId, setEditId] = useState(0);
  const [department, setDepartment] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [client, setClient] = useState("");
  const [buttonText, setButtonText] = useState("Save");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchingText, setSearchingText] = useState(false);
  const [title, setTitle] = useState("");

  const [certificateList, setCertificateList] = useState([]);

  const getCustomerList = async () => {
    let cusRes = await fetch(window.api + "certificateList");
    cusRes = await cusRes.json();
    setCustomerList(cusRes.customers);
    setDepartmentList(cusRes.departments);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getCustomerList();
  }, []);

  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const extractReferences = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    const numPages = pdfDoc.numPages;

    const references = [];

    for (let i = 1; i <= numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(" ");

      // Adjusted regex pattern to match "Certificate #: ABC-1234" format
      const regex = /\bCertificate\s+#:\s+\S+\s*-\s*\d+\b/g;
      const matches = pageText.match(regex);

      if (matches) {
        matches.forEach((ref) => {
          references.push({ reference: ref, pageNumber: i });
        });
      }
    }
    return references;
  };

  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    setButtonText("Saving");
    e.preventDefault();
    if (file) {
      const references = await extractReferences(file);
      try {
        // console.log(references);

        const formData = new FormData();
        formData.append("edit_id", editId);
        formData.append("department", department);
        formData.append("client", client);
        formData.append("title", title);
        formData.append("file", file);
        formData.append("references", JSON.stringify(references));

        let result = await fetch(window.api + "addCertificate", {
          method: "POST",
          body: formData,
        });
        result = await result.json();

        if (result.success) {
          setEditId("");
          setDepartment("");
          setClient("");
          setTitle("");
          setFile("");
          setSuccess(true);
          setButtonText("Save");
          if (searchCustomer) {
            searchByCustomer(searchCustomer);
          }
        }
      } catch (error) {
        console.error("Error sending references to API:", error);
      }
    }
  };

  async function searchByCustomer(value) {
    setSearchingText(true);
    setSearchCustomer(value);
    let searchRes = await fetch(window.api + "getAllCertifictes/" + value);
    searchRes = await searchRes.json();
    setCertificateList(searchRes.certificates);
    setSearchingText(false);
  }

  const [id, setId] = useState("");

  async function dellCertificate(value) {
    setId(value);
    let delRes = await fetch(window.api + "delManualCertificate/" + value);
    delRes = await delRes.json();
    if (delRes.success) {
      setId("");
      searchByCustomer(searchCustomer);
    }
  }

  return (
    <>
      <div className="wrapper">
        <Header />
        <SideNav />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3">
                  <h1 className="m-0">Certificates</h1>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-3">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Certificates</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Manual Certificate
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-sm-6">
                          <h3 className="card-title">
                            Add Certificate Details
                          </h3>
                        </div>
                        <div className="col-sm-6">
                          {success ? <span className="text-white float-end">Saved Successfully</span> : <></>}
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-2">
                          <select
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                            className="form-control form-control-sm"
                          >
                            <option value="">--Department--</option>
                            {Object.keys(departmentList).length > 0 ? (
                              departmentList.map((d) => (
                                <option value={d.id}>{d.name}</option>
                              ))
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                        <div className="col-sm-3">
                          <select
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                            className="form-control form-control-sm"
                          >
                            <option value="">--Client--</option>
                            {Object.keys(customerList).length > 0 ? (
                              customerList.map((c) => (
                                <option value={c.id}>
                                  {c.name}({c.address})
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <input
                              type="text"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="form-control form-control-sm"
                              placeholder="Manual Title"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="file"
                            onChange={handleFileChange}
                            accept="application/pdf"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col-sm-1">
                          <button
                            style={{ float: "right" }}
                            className="btn btn-success btn-sm"
                            disabled={!department || !client || !file}
                            onClick={handleSubmit}
                          >
                            {buttonText}
                          </button>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-6">
                          <select
                            value={searchCustomer}
                            onChange={(e) => searchByCustomer(e.target.value)}
                            className="form-control form-control-sm"
                          >
                            <option value="0">--Select Client--</option>
                            {Object.keys(customerList).length > 0 ? (
                              customerList.map((c) => (
                                <option value={c.id}>
                                  {c.name}({c.address})
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                      <br />
                      <center>
                        {searchingText ? (
                          <>
                            <p>Fetching Record.....</p>
                          </>
                        ) : (
                          <>
                            {Object.keys(certificateList).length > 0 ? (
                              <table className="table table-sm">
                                <thead>
                                  <tr>
                                    <th>Department/Title</th>
                                    <th>Customer/Address</th>
                                    <th>File</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {certificateList.map((c, index) => (
                                    <tr key={index}>
                                      <td>{c.dept_name}<br />{c.title}</td>
                                      <td>
                                        {c.cus_name}
                                        <br />
                                        {c.cus_address}
                                      </td>
                                      <td>
                                        {c.cer_file != null ? (
                                          <a
                                            href={
                                              window.main +
                                              "downloadCertificte/" +
                                              c.cer_file
                                            }
                                            download={c.cer_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            Download
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                      </td>
                                      <td>
                                        {c.id === id ? (
                                          <>
                                            <i style={{ color: "red" }}>
                                              Deleting..
                                            </i>
                                          </>
                                        ) : (
                                          <>
                                            <i
                                              onClick={() =>
                                                dellCertificate(c.id)
                                              }
                                              style={{ color: "red" }}
                                            >
                                              Delete
                                            </i>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : null}
                          </>
                        )}
                      </center>
                    </div>
                    {/* <div className="card-footer"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
        <Footer />
      </div>
    </>
  );
};

export default ManualCertificate;
