import React from 'react'

const Footer = () => {
    return (
        <>
            <footer class="main-footer">
                <strong>Copyright &copy; 2021-{new Date().getFullYear()}. Developed By <a href="https://micrologicx.net/">Micrologicx</a>.</strong>
                All rights reserved.
                <div class="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.2.0-rc
                </div>
            </footer>
        </>
    )
}

export default Footer
